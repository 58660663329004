import React from "react";
import "./style.css";

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy-inner">
        <div className="privacy-policy-up">
          <div className="privacy-policy-img"></div>
          <div className="privacy-policy-head">
            <div className="privacy-policy-title">Privacy Policy</div>
          </div>
        </div>
        <div className="privacy-policy-down">
          <div className="last-updated-text">Last Update: 19 Aug 2024</div>
          <div className="privay-policy-text">
            Lorem ipsum dolor sit amet. Sed autem vitae eum repudiandae
            dignissimos ad suscipit voluptas sed deleniti expedita. Est
            blanditiis incidunt aut quos neque non quae dolor quo tempora fugiat
            aut consequatur perspiciatis. Ex corrupti consequatur qui maiores
            nostrum qui molestiae voluptatem et asperiores ipsam est dolores
            galisum eum galisum consequatur sed consequatur ipsam. Eum ullam
            officia non quia atque est beatae repudiandae ea amet labore id
            dolorum unde sit blanditiis saepe aut neque eveniet?
            <br />
            <br />
            Et obcaecati odio qui pariatur facilis est ratione error! Et
            corrupti reiciendis ea sequi debitis qui quisquam iusto eos eligendi
            ipsum Et obcaecati odio qui pariatur facilis est ratione error! Et
            corrupti reiciendis ea sequi debitis qui quisquam iusto eos eligendi
            ipsum Lorem ipsum dolor sit amet. Sed autem vitae eum repudiandae
            dignissimos ad suscipit voluptas sed deleniti expedita. Est
            blanditiis incidunt aut quos neque non quae dolor quo tempora fugiat
            aut consequatur perspiciatis. Ex corrupti consequatur qui maiores
            nostrum qui molestiae voluptatem et asperiores ipsam est dolores
            galisum eum galisum consequatur sed consequatur ipsam. Eum ullam
            officia non quia atque est beatae repudiandae ea amet labore id
            dolorum unde sit blanditiis saepe aut neque eveniet?
            <br />
            <br />
            Et obcaecati odio qui pariatur facilis est ratione error! Et
            corrupti reiciendis ea sequi debitis qui quisquam iusto eos eligendi
            ipsum Et obcaecati odio qui pariatur facilis est ratione error! Et
            corrupti reiciendis ea sequi debitis qui quisquam iusto eos eligendi
            ipsum Lorem ipsum dolor sit amet. Sed autem vitae eum repudiandae
            dignissimos ad suscipit voluptas sed deleniti expedita. Est
            blanditiis incidunt aut quos neque non quae dolor quo tempora fugiat
            aut consequatur perspiciatis. Ex corrupti consequatur qui maiores
            nostrum qui molestiae voluptatem et asperiores ipsam est dolores
            galisum eum galisum consequatur sed consequatur ipsam. Eum ullam
            officia non quia atque est beatae repudiandae ea amet labore id
            dolorum unde sit blanditiis saepe aut neque eveniet?
            <br />
            <br />
            Et obcaecati odio qui pariatur facilis est ratione error! Et
            corrupti reiciendis ea sequi debitis qui quisquam iusto eos eligendi
            ipsum Et obcaecati odio qui pariatur facilis est ratione error! Et
            corrupti reiciendis ea sequi debitis qui quisquam iusto eos eligendi
            ipsum
          </div>
        </div>
      </div>
    </div>
  );
};
