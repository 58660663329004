import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";

export const Header = (props) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const navList = ["Home", "About Us", "Contact Us"];
  const {
    setShowSideNavbar,
    setShowGetQuoteModal,
    scrollToAboutUs,
    scrollToContactUs,
    scrollToTop,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavItemClick = (value, key) => {
    setSelectedOption(key);
    if (value == "About Us") {
      scrollToAboutUs();
    }
    if (value == "Contact Us") {
      scrollToContactUs();
    }
  };

  

  const handleLogoClick = () => {
    if (location.pathname == "/") {
      scrollToTop();
      setSelectedOption(0);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="header">
      <div className="header-inner-container">
        <div className="header-logo" onClick={() => handleLogoClick()}>
          <img src="https://cdn.logicsol.in/images/logo.png" alt="" />
        </div>
        <div className="hamburger-icon" onClick={() => setShowSideNavbar(true)}>
          <RxHamburgerMenu />
        </div>
        <div className="header-nav-bar">
          {navList.map((value, key) => {
            return (
              <>
                <div
                  className={
                    selectedOption == key
                      ? "nav-bar-item selected-nav"
                      : "nav-bar-item"
                  }
                  key={key}
                  onClick={() => handleNavItemClick(value, key)}
                >
                  {value}
                </div>
              </>
            );
          })}
          <button
            className="nav-bar-btn"
            onClick={() => setShowGetQuoteModal(true)}
          >
            Get a quote
          </button>
        </div>
      </div>
    </div>
  );
};
