import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-inner-container">
        <div className="footer-up">
          <div className="footer-section-1">
            <div className="footer-section-item">
              <img src="https://cdn.logicsol.in/images/logo-white.png" alt="" />
            </div>
            <div className="footer-section-item">
              Sandhu Commercial Tower 2, Ferozpur Road, Gurdev Nagar, Ludhiana,
              Punjab 141001
            </div>
          </div>
          <div className="footer-setion-2">
            <div className="footer-section">
              <div className="footer-section-item">Home</div>
              <div className="footer-section-item">About Us</div>
              <div className="footer-section-item">Blog</div>
            </div>
            <div className="footer-section">
              <Link to="privacy-policy">
                <div className="footer-section-item">Privacy Policy</div>
              </Link>
              <Link to="terms-conditions">
                <div className="footer-section-item">Terms & Conditions</div>
              </Link>
              <div className="footer-section-item">Contact Us</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-down">
        Copyright &#169; 2024. All rights reserved
      </div>
    </div>
  );
};
