import React, { useState, useEffect } from "react";
import "./style.css";
import client from "../../images/client2.jfif";
import background from "../../images/background.jpg";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoStar } from "react-icons/io5";
import { IoMdArrowUp } from "react-icons/io";
import Slider from "react-slick";
import { useRef } from "react";
import api from "../../AxiosInstance";

export const Homepage = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const {
    showSideNavbar,
    setShowSideNavbar,
    showGetQuoteModal,
    setShowGetQuoteModal,
    aboutUsRef,
    contactUsRef,
    scrollToTop,
  } = props;
  const navList = ["Home", "About Us", "Contact Us", "Blog"];
  const [selectedNavItem, setSelectedNavItem] = useState(0);

  const [isVisible, setIsVisible] = useState(false);

  // Show the button when scrolling down
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  let sliderRef = useRef;
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    cssEase: "linear",
    slidesToShow: 7,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 1,
        },
      },
    ],
  };

  const clientSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    cssEase: "linear",
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const contactUsSubmit = () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      subject: subject,
      message: message,
    };
    api
      .post("contact", payload)
      .then((response) => {
        console.log("response", response);
        resetContactUsForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetContactUsForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const handleSidebarQuoteButtonClick = () => {
    setShowSideNavbar(false);
    setShowGetQuoteModal(true);
  };

  return (
    <div className="homepage">
      {isVisible && (
        <button className="scroll-to-top-btn" onClick={() => scrollToTop()}>
          <IoMdArrowUp />
        </button>
      )}
      {showSideNavbar && (
        <div className="side-nav-bar">
          <div className="side-nav-bar-inner">
            <div className="side-nav-bar-menu">
              <div className="side-nav-bar-head">
                <div className="side-nav-bar-heading">Menu</div>
                <div
                  className="side-nav-bar-close-btn"
                  onClick={() => setShowSideNavbar(false)}
                >
                  <IoClose />
                </div>
              </div>
              {navList.map((value, key) => {
                return (
                  <>
                    <div
                      className={
                        selectedNavItem == key
                          ? "side-nav-bar-item selected-side-nav"
                          : "side-nav-bar-item"
                      }
                      key={key}
                      onClick={() => setSelectedNavItem(key)}
                    >
                      {value}
                    </div>
                  </>
                );
              })}
              <button
                className="nav-bar-btn"
                onClick={() => handleSidebarQuoteButtonClick()}
              >
                Get a quote
              </button>
            </div>
          </div>
        </div>
      )}
      {showGetQuoteModal && (
        <div className="modal">
          <div className="inner-modal">
            <div className="get-quote-up">
              <div className="get-quote-modal-img"></div>
              <div className="get-quote-head">
                <div className="get-quote-modal-close">
                  <div
                    className="get-quote-modal-close-btn"
                    onClick={() => setShowGetQuoteModal(false)}
                  >
                    <IoClose />
                  </div>
                </div>
                <div className="get-quote-modal-text">
                  <div className="get-quote-title">
                    Have an idea and don't know how to start?
                  </div>
                  <div className="get-quote-label">
                    We have a great team of tech ninjas who can help bring your
                    ideo to life!
                  </div>
                </div>
              </div>
            </div>
            <div className="get-quote-down">
              <div className="get-quote-modal-heading">
                <span>Get Quotes</span>
              </div>
              <div className="get-quote-form">
                <div className="input-box">
                  <div className="input-field-1">
                    <input
                      type="text"
                      className="input-text-field"
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </div>
                  <div className="input-field-1">
                    <input
                      type="text"
                      className="input-text-field"
                      placeholder="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-field">
                    <input
                      type="text"
                      className="input-text-field"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-field">
                    <input
                      type="text"
                      className="input-text-field"
                      placeholder="Subject"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-field">
                    <textarea
                      className="input-textarea"
                      placeholder="Message..."
                      rows={6}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                  </div>
                </div>
                <div className="input-box-btn">
                  <button
                    className="get-quote-modal-btn"
                    onClick={() => contactUsSubmit()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="homepage-inner-container">
        <div className="get-quote-container">
          <div className="get-quote-inner"></div>
          <div className="get-quote-text">
            Tech Experts You Need for <br />
            Your Custom Solution
            <button
              className="get-quote-btn"
              onClick={() => setShowGetQuoteModal(true)}
            >
              Get a quote
            </button>
          </div>
        </div>
        <div className="tech-stack-container">
          <div className="tech-stack-left">
            <span>Tech Stack</span>
          </div>
          <div className="tech-stack-right">
            <Slider {...settings}>
              <div className="tech-stack-item">
                <img
                  src="https://cdn.logicsol.in/images/tech-stack/reactjs.png"
                  alt=""
                />
              </div>
              <div className="tech-stack-item">
                <img
                  src="https://cdn.logicsol.in/images/tech-stack/laravel.png"
                  alt=""
                />
              </div>
              <div className="tech-stack-item">
                <img
                  src="https://cdn.logicsol.in/images/tech-stack/aws.png"
                  alt=""
                />
              </div>
              <div className="tech-stack-item">
                <img
                  src="https://cdn.logicsol.in/images/tech-stack/nodejs.png"
                  alt=""
                />
              </div>
              <div className="tech-stack-item">
                <img
                  src="https://cdn.logicsol.in/images/tech-stack/flutter.png"
                  alt=""
                />
              </div>
              <div className="tech-stack-item">
                <img
                  src="https://cdn.logicsol.in/images/tech-stack/vuejs.png"
                  alt=""
                />
              </div>
              <div className="tech-stack-item">
                <img
                  src="https://cdn.logicsol.in/images/tech-stack/mongodb.png"
                  alt=""
                />
              </div>
            </Slider>
          </div>
        </div>
        <div className="why-choose-container">
          <div className="why-choose-left">
            <div className="why-choose-heading">
              <span>Why Choose Us?</span>
            </div>
            <div className="why-choose-list">
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">
                  We bridge the gap between technology and client requirements
                </div>
              </div>
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">
                  The Cost Factor - An Economic truth
                </div>
              </div>
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">
                  After delivery and maintenance support
                </div>
              </div>
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">
                  The open and transperant approach
                </div>
              </div>
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">
                  Direct access to decision makers
                </div>
              </div>
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">We love what we do</div>
              </div>
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">Workflow Automation</div>
              </div>
              <div className="why-choose-list-item">
                <div className="bullet-tick-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-text">Easy Setup</div>
              </div>
            </div>
          </div>
          <div className="why-choose-right"></div>
        </div>
        <div className="about-us-container" ref={aboutUsRef}>
          <div className="about-us-left"></div>
          <div className="about-us-right">
            <div className="about-us-heading">
              <span>About Us</span>
            </div>
            <div className="about-us-text">
              Lorem ipsum dolor sit amet. Sed autem vitae eum repudiandae
              dignissimos ad suscipit voluptas sed deleniti expedita. Est
              blanditiis incidunt aut quos neque non quae dolor quo tempora
              fugiat aut consequatur perspiciatis. Ex corrupti consequatur qui
              maiores nostrum qui molestiae voluptatem et asperiores ipsam est
              dolores galisum eum galisum consequatur sed consequatur ipsam. Eum
              ullam officia non quia atque est beatae repudiandae ea amet labore
              id dolorum unde sit blanditiis saepe aut neque eveniet?
              <br />
              <br />
              Et obcaecati odio qui pariatur facilis est ratione error! Et
              corrupti reiciendis ea sequi debitis qui quisquam iusto eos
              eligendi ipsum Et obcaecati odio qui pariatur facilis est ratione
              error! Et corrupti reiciendis ea sequi debitis qui quisquam iusto
              eos eligendi ipsum
            </div>
          </div>
        </div>
        <div className="client-say-container">
          <div className="client-say-heading">
            <span>What Our Client Says</span>
          </div>
          <div className="client-say-data">
            <div className="client-say-arrow" onClick={() => previous()}>
              <IoIosArrowBack />
            </div>
            <Slider
              {...clientSettings}
              ref={(slider) => {
                sliderRef = slider;
              }}
            >
              <div className="client-say-inner">
                <div className="client-profile-pic">
                  <img src={client} alt="" height={"110px"} />
                </div>
                <div className="client-name-text">Will Smith</div>
                <div className="client-feedback-text">
                  Et obcaecati odio qui pariatur facilis est ratione error! Et
                  corrupti reiciendis ea sequi debitis qui quisquam iusto eos
                  eligendi ipsum Et obcaecati odio qui pariatur facilis.
                </div>
                <div className="rating-star">
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <FaRegStarHalfStroke />
                  <IoIosStarOutline />
                </div>
              </div>
              <div className="client-say-inner">
                <div className="client-profile-pic">
                  <img src={client} alt="" height={"110px"} />
                </div>
                <div className="client-name-text">Will Smith</div>
                <div className="client-feedback-text">
                  Et obcaecati odio qui pariatur facilis est ratione error! Et
                  corrupti reiciendis ea sequi debitis qui quisquam iusto eos
                  eligendi ipsum Et obcaecati odio qui pariatur facilis.
                </div>
                <div className="rating-star">
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <FaRegStarHalfStroke />
                  <IoIosStarOutline />
                </div>
              </div>
              <div className="client-say-inner">
                <div className="client-profile-pic">
                  <img src={client} alt="" height={"110px"} />
                </div>
                <div className="client-name-text">Will Smith</div>
                <div className="client-feedback-text">
                  Et obcaecati odio qui pariatur facilis est ratione error! Et
                  corrupti reiciendis ea sequi debitis qui quisquam iusto eos
                  eligendi ipsum Et obcaecati odio qui pariatur facilis.
                </div>
                <div className="rating-star">
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <FaRegStarHalfStroke />
                  <IoIosStarOutline />
                </div>
              </div>
              <div className="client-say-inner">
                <div className="client-profile-pic">
                  <img src={client} alt="" height={"110px"} />
                </div>
                <div className="client-name-text">Will Smith</div>
                <div className="client-feedback-text">
                  Et obcaecati odio qui pariatur facilis est ratione error! Et
                  corrupti reiciendis ea sequi debitis qui quisquam iusto eos
                  eligendi ipsum Et obcaecati odio qui pariatur facilis.
                </div>
                <div className="rating-star">
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <FaRegStarHalfStroke />
                  <IoIosStarOutline />
                </div>
              </div>
            </Slider>
            <div className="client-say-arrow" onClick={() => next()}>
              <IoIosArrowForward />
            </div>
          </div>
        </div>
        <div className="contact-us-container" ref={contactUsRef}>
          <div className="contact-us-left">
            <div className="client-say-heading">
              <span>Contact Us</span>
            </div>
            <div className="contact-us-form">
              <div className="input-box">
                <div className="input-field-1">
                  <input
                    type="text"
                    className="input-text-field"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </div>
                <div className="input-field-1">
                  <input
                    type="text"
                    className="input-text-field"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-field">
                  <input
                    type="text"
                    className="input-text-field"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-field">
                  <input
                    type="text"
                    className="input-text-field"
                    placeholder="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-field">
                  <textarea
                    className="input-textarea"
                    placeholder="Message..."
                    rows={6}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                </div>
              </div>
              <div className="input-box-btn">
                <button
                  className="get-quote-btn"
                  onClick={() => contactUsSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="contact-us-right">
            <div className="google-map-location">
              {/* <img src={background} alt="" height={"200px"} /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3423.5807730555575!2d75.8199971746688!3d30.898386677609558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a83cf66c5d187%3A0xaa02a81cc527440d!2sSandhu%20Commercial%20Tower%202%2C%20Ludhiana%20Rd%2C%20Gurdev%20Nagar%2C%20Ludhiana%2C%20Punjab%20141001!5e0!3m2!1sen!2sin!4v1727350292724!5m2!1sen!2sin"
                width="100%"
                height="200px"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact-us-info">
              <div className="head-office-section">
                <div className="location-icon">
                  <HiLocationMarker />
                </div>
                <div className="head-office-address">
                  <div className="contact-us-head">Head Office</div>
                  <div className="contact-us-label">
                    Sandhu Commercial Tower 2, Ferozpur Road, Gurdev Nagar,
                    Ludhiana, Punjab 141001
                  </div>
                </div>
              </div>
              <div className="contact-info">
                <div className="head-office-section">
                  <div className="location-icon">
                    <FaPhone />
                  </div>
                  <div className="head-office-address">
                    <div className="contact-us-head">Contact No.</div>
                    <div className="contact-us-label-1">+91 78886-12727</div>
                  </div>
                </div>
                <div className="head-office-section">
                  <div className="location-icon">
                    <IoMdMail />
                  </div>
                  <div className="head-office-address">
                    <div className="contact-us-head">Email Address</div>
                    <div className="contact-us-label-1">
                      support@logicsol.in
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="have-idea-container">
          <div className="have-idea-left">
            <div className="have-idea-title">
              Have an idea and don't know how to start?
            </div>
            <div className="have-idea-label">
              We have a great team of tech ninjas who can help bring your ideo
              to life!
            </div>
          </div>
          <div className="have-idea-right">
            <button
              className="get-quote-btn"
              onClick={() => setShowGetQuoteModal(true)}
            >
              Get a quote
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
