import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Homepage } from "./pages/Homepage/Homepage";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import { TermsAndCondition } from "./pages/TermsAndCondition/TermsAndCondition";
import Cookies from "js-cookie";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./App.css";

function App() {
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [showGetQuoteModal, setShowGetQuoteModal] = useState(false);
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [showCustomizeCookieOptionModal, setShowCustomizeCookieOptionModal] =
    useState(false);
  const [isAllowedFunctionalCookie, setIsAllowedFunctionalCookie] =
    useState(false);
  const [isAllowedAnalyticsCookie, setIsAllowedAnalyticsCookie] =
    useState(false);
  const [isAllowedAdvertisementCookie, setIsAllowedAdvertisementCookie] =
    useState(false);

  const contactUsRef = useRef();
  const aboutUsRef = useRef();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  const scrollToContactUs = () => {
    const { current } = contactUsRef;
    if (current !== null) {
      current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  const scrollToAboutUs = () => {
    const { current } = aboutUsRef;
    if (current !== null) {
      current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  const showCookiePopup = () => {
    const value = Cookies.get("isAllowedFunctionalCookie");
    if (value !== undefined) {
      setShowCookieModal(false);
    } else {
      setShowCookieModal(true);
    }
  };

  useEffect(() => {
    showCookiePopup();
  }, []);

  const handleAcceptAllClick = () => {
    Cookies.set("isAllowedFunctionalCookie", "true", { expires: 365 });
    Cookies.set("isAllowedAnalyticsCookie", "true", { expires: 365 });
    Cookies.set("isAllowedAdvertisementCookie", "true", { expires: 365 });
    setShowCookieModal(false);
  };

  const handleRejectAllClick = () => {
    Cookies.set("isAllowedFunctionalCookie", "false", { expires: 365 });
    Cookies.set("isAllowedAnalyticsCookie", "false", { expires: 365 });
    Cookies.set("isAllowedAdvertisementCookie", "false", { expires: 365 });
    setShowCookieModal(false);
  };

  const handleSavePreferencesClick = () => {
    Cookies.set(
      "isAllowedFunctionalCookie",
      isAllowedFunctionalCookie.toString(),
      { expires: 365 }
    );
    Cookies.set(
      "isAllowedAnalyticsCookie",
      isAllowedAnalyticsCookie.toString(),
      { expires: 365 }
    );
    Cookies.set(
      "isAllowedAdvertisementCookie",
      isAllowedAdvertisementCookie.toString(),
      { expires: 365 }
    );
    setShowCookieModal(false);
  };

  return (
    <div className="App">
      {showCookieModal && (
        <div className="cookie-container">
          <div className="cookie-inner">
            <div className="cookie-section">
              <div
                className={
                  showCustomizeCookieOptionModal
                    ? "cookie-left w-100"
                    : "cookie-left"
                }
              >
                This website uses cookies that help the website to function and
                also to track how you interact with our website. But for us to
                provide the best user experience, enable the specific cookies
                from Settings, and click on Accept.
              </div>
              {!showCustomizeCookieOptionModal && (
                <div className="cookie-right">
                  <div className="cookie-btns">
                    <div
                      className="cookie-reject-btn"
                      onClick={() =>
                        setShowCustomizeCookieOptionModal(
                          !showCustomizeCookieOptionModal
                        )
                      }
                    >
                      Customize
                    </div>
                    <div
                      className="cookie-reject-btn"
                      onClick={() => handleRejectAllClick()}
                    >
                      Reject All
                    </div>
                    <div
                      className="cookie-accept-btn"
                      onClick={() => handleAcceptAllClick()}
                    >
                      Accept All
                    </div>
                  </div>
                </div>
              )}
            </div>
            {showCustomizeCookieOptionModal && (
              <>
                <div className="customize-option">
                  <div className="customize-option-left">
                    <div className="customize-option-title">
                      Functional Cookies
                    </div>
                    <div className="customize-option-desc">
                      Functional cookies help to perform certain functionalities
                      like sharing the content of the website on social media
                      platforms, collect feedbacks and other third-party
                      features.
                    </div>
                  </div>
                  <div className="customize-option-right">
                    <Toggle
                      checked={isAllowedFunctionalCookie}
                      className="customize-toggle"
                      icons={false}
                      onChange={(e) =>
                        setIsAllowedFunctionalCookie(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div className="customize-option">
                  <div className="customize-option-left">
                    <div className="customize-option-title">
                      Analytics Cookies
                    </div>
                    <div className="customize-option-desc">
                      Analytics cookies are used to understand how visitors
                      interact with the website and get information on metrics
                      such as the number of visitors, bounce rate, traffic
                      source etc.
                    </div>
                  </div>
                  <div className="customize-option-right">
                    <Toggle
                      checked={isAllowedAnalyticsCookie}
                      className="customize-toggle"
                      icons={false}
                      onChange={(e) =>
                        setIsAllowedAnalyticsCookie(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div className="customize-option">
                  <div className="customize-option-left">
                    <div className="customize-option-title">
                      Advertisement Cookies
                    </div>
                    <div className="customize-option-desc">
                      Advertisement cookies are used to deliver visitors with
                      customized advertisements based on the pages they visited
                      before and analyze the effectiveness of the ad campaign.
                    </div>
                  </div>
                  <div className="customize-option-right">
                    <Toggle
                      checked={isAllowedAdvertisementCookie}
                      className="customize-toggle"
                      icons={false}
                      onChange={(e) =>
                        setIsAllowedAdvertisementCookie(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div className="cookie-bottom-btns">
                  <div
                    className="cookie-accept-btn"
                    onClick={() => handleRejectAllClick()}
                  >
                    Reject All
                  </div>
                  <div
                    className="cookie-reject-btn"
                    onClick={() => handleSavePreferencesClick()}
                  >
                    Save My Preferences
                  </div>

                  <div
                    className="cookie-accept-btn"
                    onClick={() => handleAcceptAllClick()}
                  >
                    Accept All
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Header
        setShowSideNavbar={setShowSideNavbar}
        setShowGetQuoteModal={setShowGetQuoteModal}
        scrollToContactUs={scrollToContactUs}
        scrollToAboutUs={scrollToAboutUs}
        scrollToTop={scrollToTop}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Homepage
              showSideNavbar={showSideNavbar}
              setShowSideNavbar={setShowSideNavbar}
              showGetQuoteModal={showGetQuoteModal}
              setShowGetQuoteModal={setShowGetQuoteModal}
              contactUsRef={contactUsRef}
              aboutUsRef={aboutUsRef}
              scrollToTop={scrollToTop}
            />
          }
        />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-conditions" element={<TermsAndCondition />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
