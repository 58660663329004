import axios from 'axios';

const api = axios.create({
    baseURL: "https://api.logicsol.in/api/", // Laravel API base URL
    headers: {
        'Content-Type': 'application/json',
        // Add any other headers you need
    },
});

export default api;